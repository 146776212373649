import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,523.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
    <path d="M5028 5162 c3 -50 -2 -91 -18 -157 -23 -89 -118 -340 -145 -381 -12
-19 -26 -21 -142 -27 -209 -11 -371 -45 -477 -100 -98 -52 -110 -99 -39 -155
113 -88 423 -146 788 -145 295 1 511 26 865 100 83 18 297 76 305 84 12 12 13
12 -93 -11 -119 -26 -321 -55 -497 -72 -190 -18 -834 -18 -975 0 -206 27 -340
69 -340 109 0 27 57 69 125 92 100 32 445 74 445 53 0 -4 -21 -51 -47 -104
-25 -54 -43 -101 -40 -105 9 -8 21 8 100 125 l62 93 75 -2 c41 -1 78 -3 81 -5
4 -2 -8 -50 -26 -106 -18 -57 -31 -105 -28 -108 7 -8 40 40 85 127 l43 83 65
0 c100 0 102 -3 48 -112 -26 -51 -44 -95 -42 -97 8 -8 43 32 100 115 l58 85
61 -5 c33 -4 132 -11 220 -16 238 -15 425 -30 468 -36 21 -3 37 -2 37 4 0 5
-19 12 -42 15 -24 3 -128 17 -233 31 -104 15 -252 32 -328 40 -76 7 -140 16
-143 19 -3 2 17 45 44 95 103 188 152 332 152 442 0 98 -2 100 -82 100 l-69 0
23 -32 c50 -71 11 -261 -105 -509 l-45 -96 -78 5 c-44 2 -80 6 -82 7 -2 1 13
40 31 86 74 179 120 343 133 473 l7 66 -57 0 -56 0 0 -42 c0 -95 -52 -335
-112 -513 l-23 -70 -70 3 c-38 2 -71 5 -73 7 -2 2 24 58 58 125 94 188 153
391 134 466 -6 22 -11 24 -59 24 l-52 0 5 -68z"/>
<path d="M6147 4244 c-35 -74 -160 -289 -227 -389 -123 -184 -259 -336 -382
-429 -112 -85 -252 -136 -373 -136 -308 1 -588 236 -977 820 -49 75 -90 113
-51 49 11 -19 42 -74 68 -124 184 -345 405 -623 600 -752 142 -95 256 -124
416 -105 330 39 615 295 836 752 91 188 115 253 111 307 l-3 46 -18 -39z"/>
<path d="M4878 3833 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5375 3750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6105 3679 c-75 -50 -177 -103 -232 -119 -60 -18 -54 -32 10 -24 64
8 153 37 230 76 55 27 57 30 57 68 0 22 -1 40 -2 40 -2 0 -30 -18 -63 -41z"/>
<path d="M5012 3510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4928 3443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5168 3443 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5388 3443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5428 3443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5820 3441 c15 -9 238 -31 310 -31 33 0 40 3 40 20 0 19 -7 20 -182
20 -106 0 -177 -4 -168 -9z"/>
<path d="M4370 3386 c-367 -72 -540 -146 -540 -232 0 -44 29 -76 109 -118 193
-101 725 -227 1191 -281 219 -26 614 -31 805 -11 88 9 176 20 195 24 35 8 35
8 38 66 3 52 1 57 -15 52 -199 -61 -404 -77 -725 -56 -695 47 -1203 137 -1378
245 -47 29 -62 50 -62 81 0 37 37 70 127 115 77 37 383 129 432 129 13 0 23 5
23 10 0 14 -12 12 -200 -24z"/>
<path d="M2703 3254 c57 -57 86 -106 93 -154 9 -67 -7 -94 -81 -141 -36 -22
-70 -49 -75 -59 -14 -27 -12 -97 5 -163 13 -49 79 -197 88 -197 2 0 -2 37 -10
83 -22 137 -27 214 -14 244 7 17 31 39 61 56 28 16 58 39 67 53 20 31 14 92
-16 155 -21 45 -125 159 -145 159 -5 0 7 -16 27 -36z"/>
<path d="M2980 3273 c0 -5 12 -20 26 -34 40 -37 73 -101 80 -152 6 -45 5 -48
-34 -84 -22 -21 -59 -52 -83 -68 -42 -30 -44 -33 -47 -89 -4 -68 17 -145 63
-235 37 -71 40 -68 25 24 -38 226 -35 242 51 295 30 18 60 40 67 49 18 22 10
99 -16 152 -29 58 -132 169 -132 142z"/>
<path d="M3284 3230 c84 -94 108 -179 65 -234 -12 -15 -45 -42 -74 -61 -30
-19 -59 -44 -66 -56 -16 -30 -5 -129 21 -198 25 -65 70 -154 75 -149 2 2 -5
53 -15 113 -32 180 -25 222 39 256 64 34 91 68 91 116 0 74 -71 187 -154 246
-25 17 -21 10 18 -33z"/>
<path d="M2720 2384 c-19 -2 -98 -9 -175 -14 -282 -22 -834 -101 -864 -124
-11 -9 -13 -36 -7 -146 15 -297 59 -585 127 -815 99 -343 215 -486 444 -553
180 -52 237 -57 750 -57 500 0 589 6 760 50 208 55 332 185 426 445 17 50 35
90 39 90 4 0 15 -11 24 -25 27 -40 73 -59 148 -58 200 3 324 169 368 493 26
189 -35 373 -137 415 -77 32 -164 10 -243 -61 l-42 -38 7 126 7 127 -23 10
c-75 32 -544 101 -858 126 -155 13 -645 18 -751 9z m695 -34 c265 -19 824 -97
865 -121 9 -5 10 -9 3 -9 -6 0 -96 -13 -200 -29 -354 -55 -554 -75 -896 -92
-380 -19 -776 7 -1231 82 -104 17 -199 34 -210 39 -28 10 27 21 324 64 514 75
925 95 1345 66z m1149 -419 c64 -71 61 -355 -6 -499 -40 -85 -136 -137 -215
-117 -45 11 -93 45 -93 66 0 11 11 74 24 142 13 67 29 162 36 210 7 48 18 93
25 100 7 7 31 29 54 48 84 70 142 86 175 50z m-1441 -257 c114 -29 270 -124
283 -172 7 -25 -10 -52 -33 -52 -8 0 -40 20 -71 44 -106 82 -205 117 -332 117
-126 0 -253 -48 -349 -132 -39 -34 -66 -37 -81 -10 -15 29 -14 30 34 76 53 50
150 103 231 126 78 23 237 24 318 3z m2 -170 c46 -16 131 -69 163 -101 7 -7
12 -25 10 -40 -4 -39 -41 -43 -77 -9 -14 13 -57 40 -96 58 -62 29 -79 33 -155
33 -71 -1 -95 -5 -143 -28 -32 -15 -76 -40 -97 -57 -41 -32 -59 -37 -78 -18
-30 30 1 74 85 124 86 52 140 65 243 60 55 -2 116 -12 145 -22z m-80 -175 c48
-13 131 -64 139 -85 9 -23 -12 -54 -35 -54 -11 0 -35 11 -52 24 -77 59 -174
57 -258 -4 -33 -23 -45 -27 -60 -19 -31 16 -23 54 19 89 64 53 161 72 247 49z
m-23 -216 c22 -20 23 -70 1 -95 -23 -26 -78 -24 -97 4 -34 49 -8 108 47 108
17 0 39 -7 49 -17z"/>
<path d="M355 1633 c125 -206 111 -416 -39 -612 -46 -59 -140 -150 -185 -179
-15 -9 -28 -19 -31 -22 -3 -3 -26 -19 -52 -35 l-48 -30 0 -177 c0 -129 3 -178
12 -178 18 0 174 87 258 143 41 28 114 89 161 136 146 145 207 264 216 427 11
181 -57 336 -213 490 -102 101 -124 111 -79 37z"/>
<path d="M1775 1063 c-541 -128 -799 -300 -707 -471 103 -193 561 -347 1255
-423 274 -30 864 -37 1177 -15 632 45 1105 156 1339 315 230 157 167 325 -174
468 -123 51 -435 139 -459 130 -6 -3 -29 -40 -51 -83 -51 -99 -156 -207 -239
-247 -76 -37 -219 -73 -370 -94 -174 -23 -887 -23 -1062 0 -148 21 -302 59
-372 93 -99 49 -217 180 -266 298 -15 36 -25 40 -71 29z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
